import { FilterTypes } from "@/views/chat/filters/FilterTypes";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

const MIGRATE_FILTER_MODULES: Array<FilterTypes> = [
  FilterTypes.Story,
  FilterTypes.VideoNote,
  FilterTypes.Spoiler,
  FilterTypes.Contact,
  FilterTypes.Dice,
  FilterTypes.Audio,
  FilterTypes.HieroglyphMessage,
  FilterTypes.RtlMessage,
  FilterTypes.Voice,
  FilterTypes.Gif,
  FilterTypes.Video,
  FilterTypes.Images,
  FilterTypes.Files,
  FilterTypes.RegularExpression,
  FilterTypes.MessageLength,
  FilterTypes.CapsMessage,
  FilterTypes.PermittedWord,
  FilterTypes.ChannelMessage,
  FilterTypes.AnyMessage,
  FilterTypes.ChatReply,
  FilterTypes.ChannelReply,
  FilterTypes.BotReply,
  FilterTypes.StickerPack,
  FilterTypes.BotCommand,
  FilterTypes.WebLinks,
  FilterTypes.EmailLinks,
  FilterTypes.Emoji,
  FilterTypes.Sticker,
  FilterTypes.SelfLinks,
  FilterTypes.Referral,
  FilterTypes.CensorWord,

  FilterTypes.StopWord,
  FilterTypes.Flood,

  FilterTypes.Location,
]

const FiltersNameModule: Partial<Record<FilterTypes, ModuleTypesEnum>> = {
  [FilterTypes.Story]: ModuleTypesEnum.StoryFilterHandlerModule,
  [FilterTypes.VideoNote]: ModuleTypesEnum.VideoNoteFilterHandlerModule,
  [FilterTypes.Spoiler]: ModuleTypesEnum.SpoilerMessageFilterHandlerModule,
  [FilterTypes.Contact]: ModuleTypesEnum.ContactFilterHandlerModule,
  [FilterTypes.Dice]: ModuleTypesEnum.DiceFilterHandlerModule,
  [FilterTypes.Audio]: ModuleTypesEnum.AudioFilterHandlerModule,
  [FilterTypes.HieroglyphMessage]: ModuleTypesEnum.HieroglyphMessageFilterHandlerModule,
  [FilterTypes.RtlMessage]: ModuleTypesEnum.RtlMessageFilterHandlerModule,
  [FilterTypes.Voice]: ModuleTypesEnum.VoiceFilterHandlerModule,
  [FilterTypes.Gif]: ModuleTypesEnum.AnimationFilterHandlerModule,
  [FilterTypes.Video]: ModuleTypesEnum.VideoFilterHandlerModule,
  [FilterTypes.Images]: ModuleTypesEnum.PhotoFilterHandlerModule,
  [FilterTypes.Files]: ModuleTypesEnum.DocumentFilterHandlerModule,
  [FilterTypes.RegularExpression]: ModuleTypesEnum.RegularExpressionFilterHandlerModule,
  [FilterTypes.MessageLength]: ModuleTypesEnum.MessageLengthFilterHandlerModule,
  [FilterTypes.CapsMessage]: ModuleTypesEnum.CapsMessageFilterHandlerModule,
  [FilterTypes.PermittedWord]: ModuleTypesEnum.PermittedWordFilterHandlerModule,
  [FilterTypes.ChannelMessage]: ModuleTypesEnum.ChannelMessageFilterHandlerModule,
  [FilterTypes.AnyMessage]: ModuleTypesEnum.AnyMessageFilterHandlerModule,
  [FilterTypes.ChatReply]: ModuleTypesEnum.ChatReplyFilterHandlerModule,
  [FilterTypes.ChannelReply]: ModuleTypesEnum.ChannelReplyFilterHandlerModule,
  [FilterTypes.BotReply]: ModuleTypesEnum.BotReplyFilterHandlerModule,
  [FilterTypes.StickerPack]: ModuleTypesEnum.StickerPackFilterHandlerModule,
  [FilterTypes.BotCommand]: ModuleTypesEnum.BotCommandFilterHandlerModule,
  [FilterTypes.WebLinks]: ModuleTypesEnum.WebLinksFilterHandlerModule,
  [FilterTypes.EmailLinks]: ModuleTypesEnum.EmailLinksFilterHandlerModule,
  [FilterTypes.Emoji]: ModuleTypesEnum.EmojiFilterHandlerModule,
  [FilterTypes.Sticker]: ModuleTypesEnum.StickerFilterHandlerModule,
  [FilterTypes.SelfLinks]: ModuleTypesEnum.SelfLinksFilterHandlerModule,
  [FilterTypes.Referral]: ModuleTypesEnum.ReferralFilterHandlerModule,
  [FilterTypes.CensorWord]: ModuleTypesEnum.CensureFilterHandlerModule,
  [FilterTypes.StopWord]: ModuleTypesEnum.StopWordFilterHandlerModule,
  [FilterTypes.Flood]: ModuleTypesEnum.FloodFilterHandlerModule,
  [FilterTypes.Location]: ModuleTypesEnum.LocationFilterHandlerModule,
}

export { MIGRATE_FILTER_MODULES, FiltersNameModule }
